import {
  enableProdMode,
  LOCALE_ID,
  ErrorHandler,
  importProvidersFrom,
} from '@angular/core';

import { environment } from './environments/environment';
import { defineCustomElements } from '@ionic/pwa-elements/loader';

import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular';
import * as CordovaSQLiteDriver from 'localforage-cordovasqlitedriver';

import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { AppComponent } from './app/app.component';
import { NgxSmartBannerModule } from '@netcreaties/ngx-smart-banner';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { customTranslateLoader } from './app/utils/custom-translate-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { Drivers } from '@ionic/storage';
import { IonicStorageModule } from '@ionic/storage-angular';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { ProjectDocumentService } from './app/services/project-document.service';
import { PollService } from './app/services/poll.service';
import { PushService } from './app/services/push.service';
import { DeepLinkService } from './app/services/deep-link.service';
import { FileService } from './app/services/file.service';
import { ErrorService } from './app/services/error.service';
import { UploadService } from './app/services/upload.service';
import { LiveStreamService } from './app/services/live-stream.service';
import { RatingService } from './app/services/rating.service';
import { TargetService } from './app/services/target.service';
import { StatisticsService } from './app/services/statistics.service';
import { CacheService } from './app/services/cache.service';
import { NetworkService } from './app/services/network.service';
import { ToastService } from './app/services/toast.service';
import { EventService } from './app/services/event.service';
import { UpdateService } from './app/services/update.service';
import { SettingsService } from './app/services/settings.service';
import { LocationService } from './app/services/location.service';
import { ImageService } from './app/services/image.service';
import { OnboardingService } from './app/services/onboarding.service';
import { DeviceService } from './app/services/device.service';
import { NotificationService } from './app/services/notification.service';
import { FeedService } from './app/services/feed.service';
import { ProjectService } from './app/services/project.service';
import { CurrentWhiteLabelApplication } from './app/utils/current-white-label-application';
import { StorageService } from './app/services/storage.service';
import { ApiInit } from './app/utils/api-init';
import { LoginService } from './app/services/login.service';
import { ApiService } from './app/services/api.service';
import { JwtInterceptor } from './app/interceptors/jwt.interceptor';
import { Interceptor } from './app/interceptors/interceptor';
import {
  HTTP_INTERCEPTORS,
  withInterceptorsFromDi,
  provideHttpClient,
  HttpClient,
} from '@angular/common/http';
import {
  IonicRouteStrategy,
  ModalController,
  provideIonicAngular,
} from '@ionic/angular/standalone';
import {
  PreloadAllModules,
  provideRouter,
  RouteReuseStrategy,
  withPreloading,
  withRouterConfig,
} from '@angular/router';
import { TranslationService } from './app/services/translation.service';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { ROUTES } from './app/app.routes';
import { ContentTextPipe } from './app/pipes/content-text.pipe';
import { LinkyPipe } from 'ngx-linky';
import { register } from 'swiper/element/bundle';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import localeNl from '@angular/common/locales/nl';
import localeEn from '@angular/common/locales/en';
import localeDa from '@angular/common/locales/da';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import { GlobalErrorHandler } from './app/utils/gloabl-error-handler';
import { NetworkInterceptor } from './app/interceptors/network.interceptor';

registerLocaleData(localeNl, 'nl');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeDa, 'da');
registerLocaleData(localeDe, 'de');
registerLocaleData(localeFr, 'fr');
register();

if (environment.production) {
  if (Capacitor.isNativePlatform()) {
    App.getInfo().then((appInfo) => {
      setupSentry(appInfo.id + '@' + appInfo.version);
    });
  } else {
    setupSentry('pwa@latest');
  }

  enableProdMode();
}

function setupSentry(release) {
  const ignoredErrors = [
    /^Error: Invalid LngLat object: (NaN, NaN)$/,
    /^TypeError: Cannot read properties of null (reading '3')$/,
    /^TypeError: null is not an object (evaluating 'r[3]')$/,
  ];
  Sentry.init(
    {
      dsn: environment.sentryDsn,
      environment: environment.environment,
      release: release,
      ignoreErrors: ignoredErrors,
    },
    SentryAngular.init
  );
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(
      ROUTES,
      withPreloading(PreloadAllModules),
      withRouterConfig({ onSameUrlNavigation: 'ignore' })
    ),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    importProvidersFrom(
      BrowserModule,
      IonicStorageModule.forRoot({
        driverOrder: [
          CordovaSQLiteDriver._driver,
          Drivers.IndexedDB,
          Drivers.LocalStorage,
        ],
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: customTranslateLoader,
          deps: [HttpClient],
        },
      }),
      InlineSVGModule.forRoot(),
      TruncateModule,
      NgxSmartBannerModule
    ),
    {
      provide: LOCALE_ID,
      deps: [TranslationService],
      useFactory: (translation: TranslationService) => {
        return translation.getAppLanguage();
      },
    },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NetworkInterceptor,
      multi: true,
    },
    ApiService,
    LoginService,
    ApiInit,
    StorageService,
    CurrentWhiteLabelApplication,
    ProjectService,
    FeedService,
    NotificationService,
    DeviceService,
    OnboardingService,
    ImageService,
    LocationService,
    SettingsService,
    UpdateService,
    EventService,
    ToastService,
    NetworkService,
    CacheService,
    StatisticsService,
    TargetService,
    RatingService,
    LiveStreamService,
    UploadService,
    ErrorService,
    FileService,
    DeepLinkService,
    PushService,
    DecimalPipe,
    PollService,
    ContentTextPipe,
    LinkyPipe,
    ProjectDocumentService,
    ModalController,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: ErrorHandler,
      useValue: SentryAngular.createErrorHandler({
        showDialog: false,
      }),
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideIonicAngular({
      mode: 'ios',
    }),
  ],
}).catch((err) => console.log(err));

// Call the element loader after the platform has been bootstrapped
defineCustomElements(window);
