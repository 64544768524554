import { EventEmitter, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { LocationService } from 'src/app/services/location.service';
import { Address } from 'src/app/interfaces/address';

@Injectable({ providedIn: 'root' })
export class DeviceService {
  public addressUpdated$ = new EventEmitter<Address>();

  constructor(
    private apiService: ApiService,
    private locationService: LocationService
  ) {}

  public getData(urlParams?: HttpParams): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let params = urlParams ? urlParams : new HttpParams();
      if (params.get('project_ids')) {
        try {
          const location = await this.locationService.getCurrentPosition(500);
          if (location) {
            params = params
              .set('location[lat]', location.coords.latitude.toString())
              .set('location[lng]', location.coords.longitude.toString());
          }
        } catch (error) {
          //No location given
        }
      }

      try {
        const response = await this.apiService
          .identifiedGet('/api/v3/device/data', params)
          .toPromise();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  public listAddresses(): Promise<Address[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.apiService
          .identifiedGet('/api/v3/device-address')
          .toPromise();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  public getAddress(id: number): Promise<Address> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.apiService
          .identifiedGet('/api/v3/device-address/' + id)
          .toPromise();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  public saveAddress(address: Address) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.apiService
          .identifiedPost('/api/v3/device-address', address)
          .toPromise();
        this.addressUpdated$.next(response);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  public updateAddress(id: number, address: Address) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.apiService
          .identifiedPut('/api/v3/device-address/' + id, address)
          .toPromise();
        this.addressUpdated$.next(response);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  public deleteAddress(address: Address) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.apiService
          .identifiedDelete('/api/v3/device-address/' + address.id)
          .toPromise();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }
}
