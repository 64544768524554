export const environment = {
  production: true,
  environment: 'test',
  baseURL: 'https://api.test.debouwapp.nl',
  whiteLabelApplicationKey: 'e7dffe4f-63ad-444e-8a19-3493f6fd9969',
  linkPrefix: 'bouwapp',
  googleMapsApiKey: 'AIzaSyCglhJKenLvVHnrdigptC3nYmbMaPU6SYs', // has to be static! is used in app.module.ts
  mapboxToken:
    'pk.eyJ1IjoiZnJhbmtlbmh1aXplbiIsImEiOiJja2Fuc3VpMDMwejR5MnNtdjdkYm4zejU5In0.EhW8O30-M2J4I2LVlW0hvw',
  serviceWorker: 'firebase-messaging-sw.js',
  sentryDsn:
    'https://d89b9de3b32c430fd06b43355c0eed62@o4505843960315904.ingest.sentry.io/4505844907245568',
  firebase: {
    apiKey: 'AIzaSyBzVKJl5nm0764EyHKnPXwfJ0wipHMUy5U',
    authDomain: 'bouwapp-test-acc.firebaseapp.com',
    databaseURL: 'https://bouwapp-test-acc.firebaseio.com',
    projectId: 'bouwapp-test-acc',
    storageBucket: 'bouwapp-test-acc.appspot.com',
    messagingSenderId: '665696762416',
    appId: '1:665696762416:web:7d72c99bdd287d86cf8bf5',
    measurementId: 'G-H5Z2M70C8S',
  },
  vapidKey:
    'BExeVG5RlsXMSyyae0kWKUon2eyYHJOKBEpsXhg4a1fu54KTd99NR7YoZ15ALt7lrTFjgRkcnqpgdkbOQvGtW2E',
  clientId: '02faeed959db314ba1d5adc710d51bd2',
  insightsEndpoint: 'https://insights.test.debouwapp.nl',
};
