import { Injectable } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';

@Injectable({ providedIn: 'root' })
export class LocationService {
  public static DEFAULT_TIMEOUT = 5000;
  public lastPosition: GeolocationPosition = null;

  constructor() {}

  public initialize() {
    Geolocation.watchPosition({}, (position, err) => {
      if (position) {
        this.lastPosition = position as GeolocationPosition;
      }
    });
  }

  /**
   * Get current location with a timeout, and if no cached position is available, force to refresh
   * @param forceGps
   * @param timeout
   * @returns {Promise<GeolocationPosition>}
   */
  public getCurrentPosition(
    timeout = LocationService.DEFAULT_TIMEOUT
  ): Promise<GeolocationPosition> {
    return new Promise<GeolocationPosition>(async (resolve, reject) => {
      if (this.lastPosition) {
        resolve(this.lastPosition);
        return;
      }
      const permissionResult = await this.hasPermission();
      if (!permissionResult) {
        resolve(null);
        return;
      }

      Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
        timeout,
      })
        .then((value) => {
          this.lastPosition = value as GeolocationPosition;
          resolve(this.lastPosition);
        })
        .catch((error) => reject(error));
    });
  }

  private async hasPermission() {
    try {
      return await Geolocation.checkPermissions();
    } catch (error) {
      return { state: 'denied' };
    }
  }
}
